const scroll = new SmoothScroll('a[href*="#"]');

const viewer = new Viewer(document.getElementById("image"), {
  navbar: false,
  toolbar: false,
  viewed() {
    // viewer.zoomTo(1);
  },
  url(image) {
    return image.getAttribute("data-original");
  }
});

const illusts = document.getElementsByClassName("illust");
const illustratorViwers = Array.from(illusts).map(
  e =>
    new Viewer(e, {
      navbar: false,
      toolbar: false,
      viewed() {
        viewer.zoomTo(1);
      },
      url(image) {
        return image.getAttribute("data-original");
      }
    })
);

setTimeout(() => {
  particlesJS("particles", {
    particles: {
      number: {
        value: 60,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: ["#FFC0CB", "#FF355A"]
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#000000"
        },
        polygon: {
          nb_sides: 5
        }
      },
      opacity: {
        value: 0.5,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 40,
        random: true,
        anim: {
          enable: true,
          speed: 10,
          size_min: 0.1,
          sync: false
        }
      },
      move: {
        enable: true,
        speed: 6,
        direction: "bottom",
        random: false,
        straight: false,
        out_mode: "out",
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    }
  });

  particlesJS("particles2", {
    particles: {
      number: {
        value: 60,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: ["#FFC0CB", "#FF355A"]
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#000000"
        },
        polygon: {
          nb_sides: 5
        }
      },
      opacity: {
        value: 0.5,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 30,
        random: true,
        anim: {
          enable: true,
          speed: 10,
          size_min: 0.1,
          sync: false
        }
      },
      move: {
        enable: true,
        speed: 6,
        direction: "bottom",
        random: false,
        straight: false,
        out_mode: "out",
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    }
  });
}, 100);

lozad(".lozad", {
  load: function(el) {
    el.src = el.dataset.src;
    el.onload = function() {
      el.classList.add("fade");
      el.classList.remove("transparent");
    };
  }
}).observe();
